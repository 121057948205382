//
//
//
//
//
//
//
//
//

import { SfImage } from '@storefront-ui/vue'
import useCmsLayout from '~/composables/useCmsLayout'

export default {
  components: {
    SfImage,
  },
  setup() {
    const { header } = useCmsLayout()

    return {
      header,
    }
  },
}
